import { ArrowRightBold, Picture, WarningFilled } from '@element-plus/icons';
import { defineComponent, ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import appFilters from '@/filters';
import { CALENDAR_STORE } from '@/store/modules/calendar';
export default defineComponent({
    components: {
        Picture,
        ArrowRightBold,
        WarningFilled
    },
    props: ['isFlexRow', 'item', 'showButton', 'active'],
    methods: {
        ...mapActions(CALENDAR_STORE, ['setSpotSecured']),
        clickCard() {
            this.$emit('click-card', this.$props.item.id);
        },
        onHandleClick() {
            if (this.item.hasSelectedDate % 2 === 0 && this.getSpotSecured) {
                this.$router.push({ name: 'opportunity-select-spot' });
            }
            else {
                this.$router.push({ name: 'opportunity-details' });
            }
        },
        gotoDetails(item) {
            if (this.hasSavedDate(item) || item.isAvailable) {
                this.$router.push({
                    name: 'opportunity-details',
                    params: { courseId: this.item.id }
                });
            }
            else {
                this.$router.push({
                    name: 'opportunity-select-spot',
                    params: { courseId: this.item.id }
                });
            }
        },
        // getLabel(item) {
        //   let label = '';
        //   if (item.spotLimkt) {
        //     label = `${item.slotLimit} slot(s) available`;
        //   }
        //   return label;
        // },
        hasSavedDate(item) {
            return item.isUpcoming && item.hasSelectedDate;
        },
        getReadableDate(value) {
            let readableDate = '';
            if (value) {
                readableDate = appFilters.formatToDate(value, 'Do MMM YYYY');
            }
            return readableDate;
        }
    },
    setup() {
        /* eslint-disable global-require */
        const url = ref(`${require('@/assets/images/sample-image-three.jpg')}`);
        const srcList = ref([
            `${require('@/assets/images/sample-image-three.jpg')}`,
        ]);
        /* eslint-disable global-require */
        return {
            url,
            srcList,
        };
    },
    computed: {
        ...mapGetters(CALENDAR_STORE, ['getSpotSecured']),
        image() {
            /* eslint-disable global-require */
            if (this.item.title === 'Unvented Hot Water' || this.item.title === 'Water Regulations') {
                return require('@/assets/images/water-regulation-hero.jpg');
            }
            if (this.item.title === 'Solar PV Installation &amp; Maintenance') {
                return require('@/assets/images/solar-pv-installation-hero.jpg');
            }
            if (this.item.title === 'Energy Efficiency For Domestic Heating') {
                return require('@/assets/images/energy-efficiency-for-domestic-heating-hero.jpg');
            }
            if (this.item.title === 'Air Source Heat Pump Level 3') {
                return require('@/assets/images/air-source-heat-pump-hero.jpg');
            }
            return require('@/assets/images/sample-image-three.jpg');
            /* eslint-disable global-require */
        }
    }
});
