<template>
    <div class="slot-container">
        <slot></slot>
    </div>
</template>
<script>

export default {
};
</script>
<style lang="scss" scoped>
.slot-container {
    // max-width: 1030px;
    display:flex;
    flex-direction: column;
    gap: 1.5rem;
    position: relative;
    // height: calc(100% - 70px);
    // padding-bottom: 5rem;
}
:deep(.el-scrollbar__bar) {
    display: block !important;
  //  :deep(.el-scrollbar__view) {
  //    padding-bottom: 2rem;
  //  }
}
:deep(.el-scrollbar__bar.is-vertical) {
    top: 0;
    right: 0;
    width: 6px !important;
}
</style>
