<template>
    <div class="no-content-container">
        <img src="@/assets/icons/graduation-hat.svg">
        <p>{{tag}}</p>
    </div>
</template>
<script>
export default {
  props: ['tag']
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.no-content-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    height: 100%;
    width: 100%;
    margin-top: 100px;

    p {
        font-family: Mulish;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.15000000596046448px;
        text-align: center;
        color: rgba(12, 15, 74, 0.5);
        margin: 0;
        width: 190px;
    }
}

@include media-sm-max-width() {
    .no-content-container {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}
@include media-xs-max-width() {
    .no-content-container {
        margin-top: 30px;
    }
}
</style>
