<template>
    <div class="hero-container">
        <div class="column-one">
           <p class="title" v-if="layoutFor === 'courses'">Selected courses based on your <span>Skills</span></p>
           <p class="title" v-if="layoutFor === 'events'">Amazing <span>Events</span> that you will like</p>
           <!-- <p class="description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p> -->
           <SearchInputComponent disabled="false" @on-search="onSearch"/>
           <p class="description" v-if="layoutFor === 'events'">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
        </div>
        <div class="column-two">
            <img src="@/assets/icons/courses-hero-image-2.svg" v-if="layoutFor === 'courses'">
            <img src="@/assets/images/events-hero-image.svg" v-if="layoutFor === 'events'">
        </div>
    </div>
    <SearchInputComponent disabled="false" isSmall />
</template>
<script>

import { defineComponent } from 'vue';

import SearchInputComponent from '@/core/components/common/header/SearchInputComponent.vue';

export default defineComponent({
  components: {
    SearchInputComponent
  },
  emits: ['on-search'],
  props: ['layoutFor'],
  data() {
    return {
    };
  },
  methods: {
    onSearch(value) {
      this.$emit('on-search', value);
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.hero-container {
    display: flex;
    gap: 4rem;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 30px;
    @media (min-width: 768px) {
        flex-direction: row;
        padding-top: 0;
    }
    .column-one {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        // max-width: 400px;
        // min-width: 368px;

        .title {
            margin: 0;
            font-family: Mulish;
            font-size: 30px;
            font-weight: 600;
            line-height: 37.65px;
            letter-spacing: 0.4000000059604645px;
            text-align: left;
            color: #0C0F4A;
            margin-top: 2rem;
            @media (min-width: 1024px) {
                font-size: 42px;
                line-height: 53px;
            }
            span {
                margin: 0;
                color: #FFA500;
            }
        }

        .description {
            font-family: Mulish;
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.4000000059604645px;
            text-align: left;
            color: rgba(12, 15, 74, 0.5);
        }
    }

    .column-two {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }
}
:deep(.el-input__inner) {
    background: rgba(12, 15, 74, 0.05);
    border: none;
    color: #111;
    border-radius: 30px;
    font-size: 14px;
    padding: 20px 20px 20px 40px;
    font-family: Arial;
}

:deep(.el-input__prefix) {
    left:10px;
    color:#111;
}

@include media-sm-max-width() {
    .hero-container {
        padding: 0;
        gap: 3rem;

        .column-one {
            .title {
                margin: 0;
            }
        }
    }
}
@include media-sm-max-width() {
    .hero-container {
        gap: 1.5rem;
        .column-one {
            .title {
                font-size: 25px;
            }
        }
    }
}
</style>
